<template lang="pug">
text#refDigit(:x="sizeMX",y="0", :font-size="sizeMFont" letter-spacing="0.01em" dominant-baseline="text-top",:text-anchor="anchor", :fill="fillColor" style="font-weight: 400; font-family: DniproCity-Medium;") {{firstPart}}
  tspan(:dy="-sizeMFont*.32",:font-size="sizeMFont*.5", v-if="firstPartIndex != ''" letter-spacing=".025em" style="font-family: DniproCity-Bold;") {{firstPartIndex}}
  tspan(:dy="sizeMFont*isIndex", :dx="sizeMFont*0.08",:font-size="sizeMFont*.8148" letter-spacing=".025em" style="font-family: DniproCity-Medium;") {{separatorPart}}
  tspan(:font-size="sizeMFont*.8148" letter-spacing=".025em" style="font-family: DniproCity-Medium;") {{secondPart}}
  tspan(:dy="-sizeMFont*.205774",:font-size="sizeMFont*.48888" letter-spacing="0.01em" style="font-family: DniproCity-Bold;") {{secondPartIndex}}
</template>

<script>
import { store } from '@/store.js';

export default {
  name: 'BuildingNumber',
  props:["sizeMX", "sizeMFont", "anchor", "fillColor"],

  data(){
    return{
      store,
      firstPart: '',
      firstPartIndex: '',
      separatorPart: '',
      secondPart: '',
      secondPartIndex: '',
      isIndex: 0,
    }
  },

  watch:
  {
    'store.inputNumber': function ()
    {
      this.wordSplit(this.store.inputNumber);
      if (this.firstPartIndex == '') 
      {
        this.isIndex = 0;
      }
      else
      {
        this.isIndex = .32;
      }
    },
  },

  methods:
  {
    wordSplit(inputedWord)
    {
      inputedWord = inputedWord.trim();
      this.firstPart = '';
      this.firstPartIndex = '';
      this.separatorPart = '';
      this.secondPartBeginIndex = '';
      this.secondPart = '';
      this.secondPartIndex = '';
      var separatorCheck = false;
      var separatorPlace = 0;
      //var firstPartIndexCheck = false;
      //var secondPartIndexCheck = false;
      inputedWord = inputedWord.replace(/([a-z])|([$&+,:.;=?@#|_'<>^*()%!])/gi, '');
      inputedWord = inputedWord.replace(/([-—])/gi, '–');

      for(let i = 0; i < inputedWord.length; i++)
      {
        if(((inputedWord[i] == '/') || (inputedWord[i] == '–') || (inputedWord[i] == ' ')) && (separatorCheck == false))
        {
          separatorCheck = true;
          separatorPlace = i;
          this.separatorPart = inputedWord[i];
        }
      }

      if(separatorCheck)
      {
        //First part
        for(let i = 0; i < separatorPlace; i++)
        {
          this.firstPart += inputedWord[i];
        }

        //removes all characters before numbers
        var counter = 0;
        if(this.firstPart.length > 0)
        {
          while(this.firstPart[counter].replace(/([\D])/gi, '') == '' && this.firstPart.length - 1 > counter)
          {
            counter++;
          }
          if(counter > 0)
          {
            this.firstPart = this.firstPart.substring(counter, this.firstPart.length);
          }

          //Saves index and removes other characters after it
          counter = 0;
          while(this.firstPart[counter].replace(/([\d])/gi, '') == '' && this.firstPart.length - 1 > counter)
          {
            counter++;
          }

          if(this.firstPart[counter].replace(/([\d])/gi, '') != '')
          {
            this.firstPartIndex = this.firstPart[counter];
            this.firstPart = this.firstPart.substring(0, counter);
          }
        }

        if(this.firstPart.length > 3)
        {
          this.firstPart = this.firstPart.substring(0, 3);
        }

        this.firstPartIndex = this.firstPartIndex.toUpperCase();

        //Second part
        for(let i = separatorPlace; i < inputedWord.length; i++)
        {
          this.secondPart += inputedWord[i];
        }

        if(this.secondPart.length > 1)
        {
          this.secondPart = this.secondPart.replace(/([\u0400–\u04FF])|([a-z$&+,.:;=?@#|'<>^*()%!/ -])/gi, '');

          //removes all characters before numbers except first character
          counter = 0;
          if(this.secondPart.length > 0)
          {
            while(this.secondPart[counter].replace(/([\D])/gi, '') == '' && this.secondPart.length - 1 > counter)
            {
              counter++;
            }
          }
          if(counter > 0)
          {
            this.secondPartBeginIndex = this.secondPart[0];
            this.secondPart = this.secondPart.substring(counter, this.secondPart.length);
            this.secondPartBeginIndex += this.secondPart;
            this.secondPart = this.secondPartBeginIndex;
          }
          
          if(this.secondPart.length > 1)
          {
            //Saves index and removes other characters after it
            if(this.secondPartBeginIndex == '')
            {
              counter = 0;
            }
            else
            {
              counter = 1;
            }

            while(this.secondPart[counter].replace(/([\d])/gi, '') == '' && this.secondPart.length - 1 > counter)
            {
              counter++;
            }

            if(this.secondPart[counter].replace(/([\d])/gi, '') != '')
            {
              this.secondPartIndex = this.secondPart[counter];
              this.secondPart = this.secondPart.substring(0, counter);
            }
          }

          if(this.secondPart.length == 1 && this.secondPart[0].replace(/([\D])/gi, '') == '')
          {
            this.secondPartIndex = '';
          }

          if(this.secondPart.length > 3 && this.secondPartBeginIndex == '')
          {
            this.secondPart = this.secondPart.substring(0, 3);
          }
          else if(this.secondPart.length > 4 && this.secondPartBeginIndex != '')
          {
            this.secondPart = this.secondPart.substring(0, 4);
          }

          this.secondPartIndex = this.secondPartIndex.toUpperCase();
        }
        else
        {
          this.secondPart = this.secondPart.substring(0, 0);
        }
      }
      else
      {
        for(let i = 0; i < inputedWord.length; i++)
        {
          this.firstPart += inputedWord[i];
        }
        
        var lettersBeforeNumbersCheck = true;
        for(let i = 0; i < this.firstPart.length; i++)
        {
          if(this.firstPart[i].replace(/([\D])/gi, '') != '')
          {
            lettersBeforeNumbersCheck = false;
          }
        }
        if(lettersBeforeNumbersCheck)
        {
          this.firstPart = '';
        }

        //removes all characters before numbers
        counter = 0;
        if(this.firstPart.length > 0)
        {
          while(this.firstPart[counter].replace(/([\D])/gi, '') == '' && this.firstPart.length - 1 > counter)
          {
            counter++;
          }
          if(counter > 0)
          {
            this.firstPart = this.firstPart.substring(counter, this.firstPart.length);
          }

          //Saves index and removes other characters after it
          counter = 0;
          while(this.firstPart[counter].replace(/([\d])/gi, '') == '' && this.firstPart.length - 1 > counter)
          {
            counter++;
          }

          if(this.firstPart[counter].replace(/([\d])/gi, '') != '')
          {
            this.firstPartIndex = this.firstPart[counter];
            this.firstPart = this.firstPart.substring(0, counter);
          }

          if(this.firstPart.length > 3)
          {
            this.firstPart = this.firstPart.substring(0, 3);
          }

          this.firstPartIndex = this.firstPartIndex.toUpperCase();
        }
      }

      this.store.mainNumber = this.firstPart.toString() + this.firstPartIndex.toString() + this.separatorPart.toString() + this.secondPart.toString() + this.secondPartIndex.toString();
    }
  },

  mounted() {
    this.wordSplit(this.store.inputNumber);
    if (this.firstPartIndex == '') 
    {
      this.isIndex = 0;
    }
    else
    {
      this.isIndex = .32;
    }    
  },  
  
  created() {
    this.wordSplit(this.store.inputNumber);  
  },
}
</script>
<style lang="scss" scoped>

</style>