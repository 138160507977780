<template lang="pug">
div
  .container-fluid
    .row
      .col
        h2.text-center {{plateName}}
    .row
      .d-xs-none.col-sm-1
      .col-12.col-sm-6
        .form-group
          label(for="house") Назва вулиці
          StreetName(
            ref="search"
            )
      .col-12.col-sm-4
        .form-group
          label(for="house") Номер будинку
          input#house.form-control(type="text", maxlength="12", v-model="store.inputNumber")
      .d-xs-none.col-sm-1

  BulkDownloadList()

  .container-fluid.full
    .row
      .col.preview
        // SVG defs
        svg(
          width="0",
          height="0",
          viewBox="0,0,0,0",
          viewport xmlns="http://www.w3.org/2000/svg"
        )
          SvgDefs(            
              :plateWidth = "this.plateWidth"            
              :clipWidth = '80'
              )

        // SVG Placeholder
        #containerPlaceholder(
          v-if="isPlaceholder"
          )
          svg#svgPlaceholder(:width="sizeM(360)", :height="sizeM(480)",:viewBox="sizeM(-20) +' '+ sizeM(-20) +' '+ sizeM(520) +' '+ sizeM(680)", :class="{ material: isMaterial}", @click="isMaterial = !isMaterial", viewport xmlns="http://www.w3.org/2000/svg")
            
            rect.plate(:width="sizeM(480)",:height="sizeM(640)" rx="16" ry="16" fill="#fff" stroke="#000" stroke-width=".25")
            g#refElements(style="display: none;")
              rect(ref="refLimit",:width="sizeM(sizeXR(0)-this.plateLimit)",:height="sizeM(640)",:x="sizeM(40)" y="0" fill="none" stroke="none")            

            text.family(:x="sizeM(50)",:y="sizeM(64)",:font-size="sizeM(34)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .025em; fill: #000;") {{mainFamilyPlace}} 
            
            text.name(ref="refName",:x="sizeM(42)",:y="sizeM(140)",:font-size="sizeM(this.fontNameSize)" text-anchor="start" letter-spacing="0.01em" style="font-weight: 400; font-family: DniproCity-Medium; fill: #000;")
              tspan(:x="sizeM(46)",:dy="sizeM(0)") {{mainNameRow1Place}}
              tspan(:x="sizeM(46)",:dy="sizeM(70)") {{mainNameRow2Place}}
              tspan(:x="sizeM(46)",:dy="sizeM(70)") {{mainNameRow3Place}}
            
            text.translit(:x="sizeM(50)",:y="sizeM(293)",:font-size="sizeM(34)" fill="#000" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .025em;")
              tspan(:x="sizeM(46)",:dy="sizeM(0)") {{mainTranslitRow1Place}}
              tspan(:x="sizeM(46)",:dy="sizeM(40)") {{mainTranslitRow2Place}}         
            
            g(:transform="'translate('+ sizeM(40) +','+ sizeM(586) +')'")        
              text#refDigit(:x="0",y="0", :font-size="sizeM(238)" letter-spacing="0.01em" dominant-baseline="text-top", text-anchor="start", fill="#000" style="font-weight: 400; font-family: DniproCity-Medium;") {{mainNumFinalPlace}}
            
            g#refWave.wave(
              :transform="'translate('+ sizeM(40) +','+ sizeM(235) +') scale('+ sizeM(1) +')'"
              )
              SvgWave(
                :plateWidth = "this.plateWidth" 
                :clipWidth = "80"
                )   

            g#svgMaterial(style="display: none;",:transform="'scale('+ sizeM(1) +')'")            
              rect.reflex(width="480" height="640" rx="16" ry="16" fill="none")

            g#fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",:cy="sizeM(this.plateHeight)-16",r="3" fill="#ccc")
              circle(cx="16",:cy="sizeM(this.plateHeight)-16",r="3" fill="#ccc")          

        // SVG Template
        #containerTemplate(
          style="position: relative;"
          v-else)

          .cartPreview(
            :class="{ collection: isCollection}",
            ref="refCart",
            :style="'top:'+ this.rectY +'px; right: calc(100vw - '+ this.rectR +'px); width:'+ this.rectW +'px; height:'+ this.rectH +'px;'", v-html="animationBuff"         
            )

          svg#svgTemplate(
            :width="sizeM(360)", 
            :height="sizeM(480)",
            :viewBox="sizeM(-20) +' '+ sizeM(-20) +' '+ sizeM(520) +' '+ sizeM(680)", 
            :class="{ material: isMaterial}", 
            @click="isMaterial = !isMaterial", 
            viewport xmlns="http://www.w3.org/2000/svg",
            preserveAspectRatio="xMinYMin meet")

            rect.plate(:width="sizeM(480)",:height="sizeM(640)" rx="16" ry="16" fill="#fff" stroke="#000" stroke-width=".25" ref="refReady")
            g#refElements(style="display: none;")
              rect(ref="refLimit",:width="sizeM(sizeXR(0)-this.plateLimit)",:height="sizeM(640)",:x="sizeM(40)",y="0",fill="none",stroke="none")            

            text.family(:x="sizeM(50)",:y="sizeM(64)",:font-size="sizeM(34)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-SemiBold; letter-spacing: .025em; fill: #000;") {{mainFamily}} 
            
            text.name(ref="refName",:x="sizeM(50)",:y="sizeM(140)",:font-size="sizeM(this.fontNameSize)" text-anchor="start" letter-spacing="0.01em" style="font-weight: 400; font-family: DniproCity-Medium; fill: #000;")
              tspan(:x="sizeM(50)",:dy="sizeM(0)") {{mainNameRow1}}
              tspan(:x="sizeM(50)",:dy="sizeM(this.fontNameSize*1.045)") {{mainNameRow2}}
              tspan(:x="sizeM(50)",:dy="sizeM(this.fontNameSize*1.045)") {{mainNameRow3}}
            
            text.translit(:x="sizeM(50)",:y="sizeM((wavePositionMultiplier*this.fontNameSize*1.045)+233)",:font-size="sizeM(33.86)" text-anchor="start" style="font-weight: 400; font-family: DniproCity-Medium; letter-spacing: .025em; fill: #000;")
              tspan(:x="sizeM(50)",:dy="sizeM(0)") {{mainTranslitRow1}}
              tspan(:x="sizeM(50)",:dy="sizeM(40.04)") {{mainTranslitRow2}}         
            
            g(:transform="'translate('+ sizeM(40) +','+ sizeM(586) +')'")
              BuildingNumber(ref="refNum", :sizeMX = "0", :sizeMFont = "sizeM(this.fontNumSize)", anchor = "start", fillColor = "#000")
            
            g#refWave.wave(:transform="'translate('+ sizeM(40) +','+ sizeM((wavePositionMultiplier*this.fontNameSize*1.045)+169) +') scale('+ sizeM(1) +')'")
              SvgWave(
                :plateWidth = "this.plateWidth" 
                :clipWidth = "80"
                ) 

            g#svgMaterial(style="display: none;",:transform="'scale('+ sizeM(1) +')'")            
              rect.reflex(width="480" height="640" rx="16" ry="16" fill="none")

            g#fix(v-if ="store.fixersDisplay") 
              circle(cx="16" cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",cy="16" r="3" fill="#ccc")
              circle(:cx="sizeM(this.plateWidth)-16",:cy="sizeM(this.plateHeight)-16",r="3" fill="#ccc")
              circle(cx="16",:cy="sizeM(this.plateHeight)-16",r="3" fill="#ccc")

            SvgRequirements(            
              :plateWidth = "this.plateWidth"
              :plateHeight = "this.plateHeight"
              :plateName = "this.plateName"
              :isWhiteBack = "true"
              :immutableSize = "false"
              )

        .sign-size.text-center 
          span розмір покажчика
          | {{sizeM(480)}}x{{sizeM(640)}} мм
    .row
      .col.svgContainer

  .container-fluid
    .row.row-cols-1.plate-options
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3
        .form-group
          .custom-control.custom-switch
            input.custom-control-input(type="checkbox" id="largerSize" v-model="store.sizeMultiplier")
            label.custom-control-label(for="largerSize") збільшений розмір покажчика
              br
              small.caption Якщо відстань від фасаду до краю хідника більше ніж&nbsp;15&nbsp;метрів      
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3
        .form-group
          .custom-control.custom-switch
            input#fixMarks.custom-control-input(type="checkbox" v-model="store.fixersDisplay")
            label.custom-control-label(for="fixMarks") показати кріплення
    
    .row.plate-download
      .col.text-center
        button#btn-download.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(false)") Завантажити                
        button#add-cart.btn.btn-primary.btn-lg(type="button", :disabled="generateNotReady", @click="convertFunc(true)") Додати до набору
    .row.plate-download
      .col-xs-12.col-sm-8.offset-sm-2.col-md-6.offset-md-3.text-center        
        p.caption.text-center Макет у форматі .pdf з літерами у кривих
          br
          br
          | Під час завантаження матеріалів ви погоджуєтесь з ліцензією 
          a(href="https://creativecommons.org/licenses/by-nd/4.0/deed.uk" target="_blank") CC BY-ND 4.0 Із 
          | Зазначенням Авторства — Без Похідних 4.0 Міжнародна
          
    .svgInstance 
      svg#svgInstance(:width="sizeM(480)", :height="sizeM(640)",:viewBox="'0 0 '+ sizeM(sizeXR(0)) +' '+ sizeM(500)", preserveAspectRatio="xMidYMin meet", viewport xmlns="http://www.w3.org/2000/svg") 

</template>

<script>
import { store } from '@/store.js';

import * as pdfFuncs from '@/svgToPdfConvertJS.js';

import BuildingNumber from '@/components/BuildingNumber.vue'
import StreetName from '@/components/StreetName.vue'
import SvgRequirements from '@/components/SvgRequirements.vue'
import SvgDefs from '@/components/SvgDefs.vue'
import SvgWave from '@/components/SvgWave.vue'
import BulkDownloadList from '@/components/BulkDownloadList.vue'

export default {
  name: 'Name',
  components: {
    BuildingNumber,
    StreetName,
    SvgRequirements,
    SvgDefs,
    SvgWave,
    BulkDownloadList,
  },

  data(){
    return{
      store,
      
      mainName: '',

      mainFamily: '',
      mainNameRow1: '',
      mainNameRow2: '',
      mainNameRow3: '',
      mainNumFinal: '',
      mainTranslitRow1: '',
      mainTranslitRow2: '',

      mainFamilyPlace: 'вулиця',
      mainNameRow1Place: 'Академіка',
      mainNameRow2Place: 'Чекмарьова',
      mainNameRow3Place: '',
      mainNumFinalPlace: '42',
      mainTranslitRow1Place: 'Akademika',
      mainTranslitRow2Place: 'Chekmareva St.',

      mainNumberPlace: '42',

      wavePositionMultiplier: 2,
    
      isMaterial: false,
      isPlaceholder: true,
      generateNotReady: true,
      isCollection: false,

      plateWidth:480,
      plateHeight:640,
      plateLimit:80,

      plateName: 'Адресний покажчик',

      fontNameSizeMax:67,
      fontNameSizeMin:47,
      fontNameSize:67,
      fontOffset:5,

      fontNumSizeMax:238,
      fontNumSizeMin:178,
      fontNumSize:238,
      fontNumOffset:20,

      rectR: 0,
      rectY: 0,
      rectW: 0,
      rectH: 0,

      animationBuff: 0,
    }
  },

  watch:
  {    
    'store.inputNumber': function()
    {
      this.minReq();
    },

    'store.inputStreetName': function()
    {
      this.minReq();
    },

    'store.bulkData.length': function()
    {
      if(this.store.bulkData.length > 0)
      {
        this.store.isCartEmpty = false;
      }
      else
      {
        this.store.isCartEmpty = true;
      }
    },
  },

  methods:
  {
    streetDataSuggest() 
    {
      this.mainFamily = this.store.streetData.type;
      var buffArr = [];
      var streetNameBuff = this.store.streetData.name;
      var streetTranslitBuff = this.store.streetData.translation;

      this.mainNameRow1 = '';
      this.mainNameRow2 = '';
      this.mainNameRow3 = '';

      this.mainTranslitRow1 = '';
      this.mainTranslitRow2 = '';

      if(!this.store.streetData.name.replace(/([0-9])/g, '*').includes('*'))
      {
        if(this.store.streetData.name.includes(' ') || this.store.streetData.name.includes('-'))
        {
          if(!this.store.streetData.name.replace(/\w*([\d-]){2}\w*/, '*').includes('*'))
          {
            streetNameBuff = this.store.streetData.name.replace(/([-])/gi, '- ');
          }

          buffArr = streetNameBuff.split(' ');
          this.mainNameRow1 = buffArr[0];
          this.mainNameRow2 = buffArr[1];
          if(buffArr.length > 2)
          {
            this.mainNameRow3 = buffArr[2];
          }
        }
        else
        {
          this.mainNameRow1 = this.store.streetData.name;
        }
      }
      else
      {
        if(streetNameBuff.split(' ').length > 2)
        {
          if(this.store.streetData.name.includes(' ') || this.store.streetData.name.includes('-'))
          {
            if(!this.store.streetData.name.replace(/\w*([\d-]){2}\w*/, '*').includes('*'))
            {
              streetNameBuff = this.store.streetData.name.replace(/([-])/gi, '- ');
            }

            buffArr = streetNameBuff.split(' ');
            this.mainNameRow1 = buffArr[0];
            this.mainNameRow2 = buffArr[1];
            if(buffArr.length > 2)
            {
              this.mainNameRow3 = buffArr[2];
            }
          }
          else
          {
            this.mainNameRow1 = this.store.streetData.name;
          }
        }
        else
        {
          this.mainNameRow1 = this.store.streetData.name;
        }
      }



      if(this.mainNameRow3 != '')
      {
        this.wavePositionMultiplier = 2;
        this.fontNumSizeMax = 218;
      }
      else if(this.mainNameRow2 != '')
      {
        this.wavePositionMultiplier = 1;
        this.fontNumSizeMax = 238;
      }
      else if(this.mainNameRow1 != '')
      {
        this.wavePositionMultiplier = 0;
        this.fontNumSizeMax = 238;
      }

      if((this.store.streetData.translation.length - 3) >= 20)
      {
        if(this.store.streetData.translation.includes(' ') || this.store.streetData.name.includes('-'))
        {
          if(!this.store.streetData.translation.replace(/\w*([\d-]){2}\w*/, '*').includes('*'))
          {
            streetTranslitBuff = this.store.streetData.translation.replace(/([-])/gi, '- ');
          }

          buffArr = streetTranslitBuff.split(' ');
          if(buffArr.length > 3)
          {
            var i = 0;
            for(i = 0; i < buffArr.length - (buffArr.length - 2); i++)
            {
              if(buffArr[i].includes('-'))
              {
                this.mainTranslitRow1 += buffArr[i];
              }
              else
              {
                this.mainTranslitRow1 += buffArr[i] + ' ';
              }
            }
            for(i; i < buffArr.length; i++)
            {
              if(buffArr[i].includes('-'))
              {
                this.mainTranslitRow2 += buffArr[i];
              }
              else
              {
                this.mainTranslitRow2 += buffArr[i] + ' ';
              }
            }
          }
          else
          {
            if(buffArr[0].includes('-'))
            {
              this.mainTranslitRow1 += buffArr[0];
            }
            else
            {
              this.mainTranslitRow1 += buffArr[0] + ' ';
            }
            for(i = 1; i < buffArr.length; i++)
            {
              if(buffArr[i].includes('-'))
              {
                this.mainTranslitRow2 += buffArr[i];
              }
              else
              {
                this.mainTranslitRow2 += buffArr[i] + ' ';
              }
            }
          }
        }
      }
      else
      {
        this.mainTranslitRow1 = this.store.streetData.translation;
      }
    },

    minReq()
    {
      if(this.store.streetData.name != this.store.inputStreetName)
      {
        this.mainFamily = '';
        this.mainNameRow1 = '';
        this.mainNameRow2 = '';
        this.mainNameRow3 = '';

        this.mainTranslitRow1 = '';
        this.mainTranslitRow2 = '';
      }

      if(this.store.streetData.name == this.store.inputStreetName || this.store.inputNumber != '')
      {
        this.isPlaceholder = false;
        this.isMaterial = true;
        if(this.store.inputStreetName.length > 0 && this.store.streetData.name == this.store.inputStreetName)
        {
          this.streetDataSuggest();
        }
      }
      else
      {
        this.isMaterial = false;
        this.isPlaceholder = true;
      }

      if(this.store.streetData.name == this.store.inputStreetName && this.store.inputNumber != '')
      {
        this.generateNotReady = false;
      }
      else
      {
        this.generateNotReady = true;        
      }
    },

    convertFunc(isBulk)
    {
      let pdfMainName = (this.mainTranslitRow1+this.mainTranslitRow2).toLowerCase().replace(/ /gm,'-').replace('.','') + this.store.mainNumber;
      let pdfNameOptions = '_address-sign';
      if(this.store.sizeMultiplier)
      {
        pdfNameOptions += '_XL';
      }
      if(this.store.fixersDisplay)
      {
        pdfNameOptions += '_FIX';
      }
      pdfNameOptions += '.pdf';

      pdfFuncs.svgToPdfCovert(10, 10, this.sizeM(480), this.sizeM(840), this.sizeM(480)+20, this.sizeM(800)+30, 
      pdfMainName, pdfNameOptions, this.sizeM(30), this.sizeM(670), this.sizeM(this.plateHeight), 'svgInstance', 'svgTemplate', isBulk, this.store.bulkData);
      
      if(isBulk)
      {
        this.getPosition();

        this.animationBuff = document.querySelector('#svgTemplate').outerHTML;

        let isCopy = false;

        if(typeof this.store.bulkData.length !== 'undefined')
        {
            for(let i = 0; i < this.store.bulkData.length; i++)
            {
                if(this.store.bulkData[i].pdfName == (pdfMainName + pdfNameOptions))
                {
                    isCopy = true;
                }  
            }
        }

        if(!isCopy)
        {
          setTimeout(() => {
            this.isCollection = true;
          }, 100); 
          setTimeout(() => {
            this.animationBuff = 0;
            this.isCollection = !this.isCollection;        
          }, 1000); 
        }
      }
    },

    getPosition() {      
      this.rectY = parseInt(this.$refs.refReady.getBoundingClientRect().y);
      this.rectW = parseInt(this.$refs.refReady.getBoundingClientRect().width);
      this.rectH = parseInt(this.$refs.refReady.getBoundingClientRect().height);
      this.rectR = parseInt(this.$refs.refReady.getBoundingClientRect().right);        
    },

    sizeM(size)
    {
      if(this.store.sizeMultiplier)
      {
        return size;
      }
      else
      {
        return size * 0.75;
      }
    },

    sizeXR(offset)
    {
      return this.plateWidth - offset;
    },

    inputField() {
      this.$refs.search.$refs.inputField.focus();      
    },

    getWidth() {      
      // limitBox – Size of the limit box for the text
      // offsetBox – Size of the offset for the calculation
      // contentBox – Size of the text box 
      var limitBox = this.$refs.refLimit.getBoundingClientRect().width;      
      var contentBox = this.$refs.refName.getBoundingClientRect().width;      

      if(limitBox < contentBox) {      
        return this.fontNameSize - this.fontOffset;
      }
      else if((limitBox > contentBox) && (this.fontNameSize == this.fontNameSizeMax)) {
        return this.fontNameSize;
      }
      else if((limitBox > contentBox) && (this.fontNameSize < this.fontNameSizeMax) && ((limitBox - contentBox) > 30)) {        
        return this.fontNameSize + this.fontOffset;
      }     
      else {        
        return this.fontNameSize;
      }
    },

    getNumWidth() {           
      var limitBox = this.$refs.refLimit.getBoundingClientRect().width;      
      var contentBox = this.$refs.refNum.$el.getBoundingClientRect().width;      

      if((limitBox < contentBox) || (this.fontNumSize > this.fontNumSizeMax))  {        
        return this.fontNumSize - this.fontNumOffset;
      }
      else if((limitBox > contentBox) && (this.fontNumSize == this.fontNumSizeMax)) {        
        return this.fontNumSize;
      }
      else if((limitBox > contentBox) && (this.fontNumSize < this.fontNumSizeMax) && ((limitBox - contentBox) > 30)) {        
        return this.fontNumSize + this.fontNumOffset;
      }     
      else {
        return this.fontNumSize;
      }
    },
  },

  mounted() {
    this.inputField();
    this.minReq();
  },  

  updated(){
    this.$nextTick(()=>{
      if(!this.isPlaceholder) {
        this.fontNameSize = this.getWidth();
        this.fontNumSize = this.getNumWidth();       
      }
    })
  },
}

</script>

<style lang="scss" scoped>
@import '@/assets/styles/_svg-templates.scss';

.container-fluid {
  max-width: 600px;
  &.full {
    max-width: none;
  }
}

@include respond-below(md) {
  svg {
    max-width: 270px;
    width: 80%;
    padding: 0 20px;
    height: auto;
  }
}

.cartPreview {  
  display: inline-block; 
  width: 300px;
  height: 160px;

  position: fixed;
  top: 200px;
  right: calc(50% - 300px);
  
  visibility: hidden;
  opacity: 1;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 4px 80px 0 rgba(0,0,0,.3);  
  border: none;

  &.collection {    
    visibility: visible;
    top: 50px !important;
    right: 50px !important;
    // background-color: rgba(255,255,255,1);
    
    opacity: 0;
    // z-index: 10000;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.3);

    transform: scale(.1);
    transform-origin: top right;

    transition: top .5s cubic-bezier(0, 0, 0.3, 1.8), right .5s ease-out, transform .5s ease-out, opacity .75s ease-in, box-shadow .5s ease-out;

  }
}

</style>